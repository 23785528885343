import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  useToast,
  VStack,
} from '@chakra-ui/react';

import DatabaseSelect from 'components/DatabaseSelect/DatabaseSelect';
import { P2 } from 'components/Typography';
import useCreateGraph from 'hooks/api/graphs/useCreateGraph';
import useOrganization from 'hooks/useOrganization';
import { Graph } from 'types/api';

type CreateGraphModalProps = {
  storageUID?: string;
  onAddStorage: Function;
  onSuccess: (data: Graph) => void;
};

export type CreateGraphFormData = {
  title: string;
  database: string;
};

function CreateGraphForm({ storageUID, onAddStorage, onSuccess }: CreateGraphModalProps) {
  const { organization } = useOrganization();
  const toast = useToast();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const { mutate: createGraph, isLoading: isCreateLoading } = useCreateGraph({
    onSuccess: onSuccess,
    onError: (error) => {
      toast({
        description: 'Error creating Agent',
        status: 'error',
        isClosable: true,
        duration: undefined,
      });
      error.message ? setErrorMessage(error.message) : setErrorMessage(JSON.stringify(error));
    },
  });

  const formMethods = useForm<CreateGraphFormData>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = formMethods;

  useEffect(() => {
    storageUID && setValue('database', storageUID);
  }, [storageUID, setValue]);

  const onSubmit = async (data: CreateGraphFormData) => {
    if (!organization?.uid) return;

    setErrorMessage(undefined);

    createGraph({
      ...data,
      organization_uid: organization.uid,
      storage_uid: data.database,
    });
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={6}>
            <FormControl id="title" isInvalid={!!errors?.title}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="My Agent"
                data-testid="graph-name-input"
                {...register('title', { required: true })}
              />
              {errors.title && <FormErrorMessage>This field is required</FormErrorMessage>}
            </FormControl>

            <DatabaseSelect onAddStorage={onAddStorage} />

            {errorMessage && (
              <P2 mt={2} color="red.600">
                {errorMessage}
              </P2>
            )}
          </VStack>
          <Flex width={'100%'} flexDir={'column'}>
            <Button
              mt={6}
              mb={2}
              mx="auto"
              type="submit"
              size="lg"
              width={'100%'}
              maxWidth={'300px'}
              isLoading={isCreateLoading}
              bg={'#49526A'}
              _hover={{ opacity: 0.75 }}
            >
              Create Agent
            </Button>

            <P2 mt={4} textAlign={'center'} color={'text2'}>
              Or click{' '}
              <Link
                color="light.action"
                fontWeight={600}
                onClick={() => {
                  const url = window.location.origin + '/members';
                  window.open(url, '_blank', 'noopener,noreferrer');
                }}
              >
                here
              </Link>{' '}
              to invite a team member to help you
            </P2>
          </Flex>
        </form>
      </FormProvider>
    </>
  );
}

export default CreateGraphForm;
