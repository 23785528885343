import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useRouter } from 'next/router';

import useOrganization from 'hooks/useOrganization';
import { Graph } from 'types/api';
import { getGraphURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphQueryKey } from 'utils/queryKeys';

function useGraph<T = Graph>(options: UseQueryOptions<Graph, AxiosError, T> = {}) {
  const router = useRouter();
  const graphUID = router.query.id as string;

  const graphEnabled =
    !!graphUID && (router.pathname.startsWith('/app') || router.pathname.startsWith('/demo'));
  const { setOrganization, organizationUID } = useOrganization();

  const url = getGraphURL(graphUID);

  return useQuery<Graph, AxiosError, T>(
    GraphQueryKey(graphUID),
    async () => {
      let res = await Axios.get<Graph>(url!);
      const graph = res?.data;

      if (graph?.organization_uid && graph?.organization_uid !== organizationUID) {
        setOrganization(graph.organization_uid);
      }
      return graph;
    },
    {
      enabled: graphEnabled,
      retry: (failureCount, error: any) => {
        // don't retry on 404 or 403
        return error?.response?.status === 404 || error?.response?.status === 403 ? false : true;
      },
      staleTime: 60 * 1000,
      keepPreviousData: true,
      refetchOnMount: false,
      ...options,
    },
  );
}

export function useGraphUID() {
  const { query } = useRouter();

  return query.id as string;
}

export default useGraph;
