import { Box } from '@chakra-ui/react';
import Head from 'next/head';

import Loader from 'components/Loader';

function FullScreenLoader({
  animate = true,
  longLoader,
}: {
  animate?: boolean;
  longLoader?: boolean;
}) {
  return (
    <>
      <Head>
        <title>Patterns | Agent</title>
      </Head>
      <Box pos={'absolute'} left="0" top="0" width="100%" height="100%" backgroundColor="white">
        <Loader animate={animate} longLoader={longLoader} />
      </Box>
    </>
  );
}

export default FullScreenLoader;
