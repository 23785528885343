import { useEffect, useState } from 'react';
import { Button, Center, Flex, Image, Spinner, Text } from '@chakra-ui/react';

import { H1 } from 'components/Typography';
import useStorages from 'hooks/api/storages/useStorages';

import ConnectDataSource from './ConnectDataSource/ConnectDataSource';
import CreateGraphFormWrapper from './CreateGraphFormWrapper';

function Onboarding({}: {}) {
  const { data: storages, isLoading: isLoadingStorages } = useStorages();

  const [showConnectData, setShowConnectData] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [addedDatabase, setAddedDatabase] = useState<string | undefined>(undefined);

  const storageCount = storages?.results.length || 0;

  useEffect(() => {
    if (isLoadingStorages) return;
    setShowWelcomeScreen(storageCount === 0);
    setShowConnectData(storageCount === 0);
  }, [isLoadingStorages, storageCount]);

  if (isLoadingStorages) {
    return (
      <Center>
        <Spinner speed={'1.5s'} size="md" />
      </Center>
    );
  }

  return (
    <Flex h={'100%'} w={'100%'} flexDir={'column'}>
      {showWelcomeScreen && (
        <Flex
          h={'100%'}
          w={'100%'}
          flexDir={'column'}
          maxWidth={'600px'}
          mx={'auto'}
          mt={{ base: 5, md: 20 }}
        >
          <Image src={'/logos/patterns-icon.svg'} alt="logo compact" height={`100px`} />
          <H1 textAlign={'center'} my={5} fontSize={'40px'}>
            Welcome to Patterns
          </H1>
          <Text textAlign={'center'} fontSize="16px" color="text1" fontWeight={500}>
            Connect your data and have all your questions answered by AI agents that understand your
            data and improve with usage.
          </Text>
          <Button
            size={'md'}
            maxWidth={'300px'}
            width={'100%'}
            mx={'auto'}
            mt={8}
            bg={'black'}
            fontWeight={400}
            _hover={{ opacity: 0.75 }}
            onClick={() => setShowWelcomeScreen(false)}
          >
            Get Started
          </Button>
        </Flex>
      )}
      {!showWelcomeScreen && showConnectData && (
        <>
          <H1 textAlign={'center'} mb={{ base: 5, md: 10 }}>
            Select a data source
          </H1>
          <ConnectDataSource
            onSuccess={(storage) => setAddedDatabase(storage.uid)}
            GoBack={storageCount === 0 ? undefined : () => setShowConnectData(false)}
          />
        </>
      )}
      {!showWelcomeScreen && !showConnectData && (
        <CreateGraphFormWrapper
          onAddStorage={() => setShowConnectData(true)}
          storageUID={addedDatabase}
        />
      )}
    </Flex>
  );
}

export default Onboarding;
