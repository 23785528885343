import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { BiCaretDown } from 'react-icons/bi';
import { FiPlus } from 'react-icons/fi';
import { Box, Button, Center, chakra, Flex, FormLabel, Select, Spinner } from '@chakra-ui/react';

import useStorages from 'hooks/api/storages/useStorages';

const CaretDownIcon = chakra(BiCaretDown);

export const selectStyles = {
  h: 8,
  color: 'gray.900',
  icon: <CaretDownIcon fontSize="18px" fill="gray.700" />,
};

function DatabaseSelect({ onAddStorage }: { onAddStorage?: Function }) {
  const { data, isLoading } = useStorages();

  const storages = useMemo(
    () => (data ? data.results.sort((a, b) => a.name.localeCompare(b.name)) : []),
    [data],
  );

  const { register, watch, setValue } = useFormContext();

  const dbValue = watch('database');

  useEffect(() => {
    if (!dbValue && storages.length > 0) {
      setValue('database', storages[0].uid);
    }
  }, [dbValue, storages, setValue]);

  return (
    <Box width={'100%'}>
      {onAddStorage && (
        <Flex>
          <FormLabel>Select a database to use with this agent</FormLabel>
          <Button
            onClick={() => onAddStorage()}
            leftIcon={<FiPlus />}
            variant={'outline'}
            ml={'auto'}
            borderColor={'#49526A'}
            color={'text1'}
            _hover={{ bg: '#E8E9EC' }}
          >
            Add Database
          </Button>
        </Flex>
      )}

      {isLoading ? (
        <Center>
          <Spinner speed={'1.5s'} h={8} w={8} />
          <input hidden {...register('database', { required: true })} />
        </Center>
      ) : (
        <Select
          {...selectStyles}
          {...register('database', {
            required: true,
          })}
          placeholder={
            storages.length === 0 ? 'No databases found. Please add a database.' : undefined
          }
          isDisabled={storages.length === 0}
        >
          {storages.map((storage) => (
            <option key={storage.uid} value={storage.uid}>
              {storage.name}
            </option>
          ))}
        </Select>
      )}
    </Box>
  );
}

export default DatabaseSelect;
