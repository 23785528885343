import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, CloseButton } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import FullScreenLoader from 'components/FullScreenLoader';
import useGraphs from 'hooks/api/graphs/useGraphs';
import { useOrganizationUID } from 'hooks/useOrganization';
import PageLayout from 'layouts/PageLayout/PageLayout';
import { makeGraphURL } from 'utils';

import Onboarding from './Onboarding';

function HomeView() {
  const { data: graphResults, isLoading } = useGraphs();

  const graphs = useMemo(() => graphResults?.results || [], [graphResults]);

  const { query, replace } = useRouter();
  const organizationUID = useOrganizationUID();

  const createNewBot = query.newBot === 'true';
  const hasGraphs = graphs.length > 0;
  const showLoading = hasGraphs && !createNewBot;

  const goToGraph = useCallback(async () => {
    let appUID = localStorage.getItem(`${organizationUID}:app`);
    let graph = graphs.find((graph) => graph.uid === appUID);
    if (!graph && graphs.length > 0) {
      graphs.sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1));
      graph = graphs[0];
    }

    if (graph) {
      await replace(makeGraphURL({ uid: graph.uid, slug: graph.slug }));
    }
  }, [graphs, organizationUID, replace]);

  useEffect(() => {
    if (isLoading || createNewBot || graphs.length === 0) {
      return;
    } else {
      goToGraph();
    }
  }, [isLoading, createNewBot, goToGraph, graphs.length]);

  if (showLoading) {
    return <FullScreenLoader />;
  }

  return (
    <PageLayout>
      {hasGraphs && (
        <CloseButton
          size={'lg'}
          position={'absolute'}
          top="45px"
          right={0}
          m={3}
          zIndex={2}
          onClick={goToGraph}
        />
      )}
      <Box width="100%" bg={'bg4'} backgroundColor={'white'}>
        <Box maxW="800px" w="90%" px={4} my={{ base: 3, sm: '5vh', md: '5vh' }} mx={'auto'}>
          <Onboarding />
        </Box>
      </Box>
    </PageLayout>
  );
}

export default HomeView;
