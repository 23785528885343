import { ComponentType, FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';

import Loader from 'components/Loader';
import { useAuth } from 'contexts/AuthContext';
import { FB_PIXEL_INIT } from 'utils/fpixel';

const privateRoute = <P extends object>(Component: ComponentType<P>) => {
  return function PrivateComponent(props: any): FC<P> | ReactElement {
    const router = useRouter();
    const { isLoading, isAuthenticated, account, accountError } = useAuth();

    const isGraphPage = router.pathname.includes('/app');
    const isDemo = router.pathname.includes('/demo');

    const isAuthenticating = useMemo(() => {
      return (
        isLoading ||
        !!(
          isAuthenticated &&
          router.pathname === '/home' &&
          router.query.code &&
          router.query.state
        )
      );
    }, [isLoading, isAuthenticated, router.pathname, router.query.code, router.query.state]);

    const isAuthenticatedCustom = useMemo(() => {
      // graph page is edge case, where you don't have to be authenticated
      // but if you are, then we enforce isVerified and isSetup
      return (isAuthenticated || isGraphPage || isDemo) && !isAuthenticating;
    }, [isAuthenticated, isGraphPage, isDemo, isAuthenticating]);

    const unverifiedEmailError = accountError?.response?.data.detail === 'unverified email';
    const incompleteSetupError = accountError?.response?.data.detail === 'incomplete setup';

    const isUnverified = useMemo(() => {
      return isAuthenticated && unverifiedEmailError && !router.pathname.includes('/verify-email');
    }, [isAuthenticated, router.pathname, unverifiedEmailError]);

    const isUnsetup = useMemo(() => {
      return isAuthenticated && incompleteSetupError && !router.pathname.includes('/account-setup');
    }, [incompleteSetupError, isAuthenticated, router.pathname]);

    const hasNoOrgs = useMemo(() => {
      // if user is logged in, verified, and setup, but has no orgs, redirect to org setup
      return (
        isAuthenticated &&
        !unverifiedEmailError &&
        !incompleteSetupError &&
        account?.organizations !== undefined &&
        Object.keys(account.organizations).length === 0 &&
        !router.pathname.includes('/verify-email') &&
        !router.pathname.includes('/org-setup')
      );
    }, [
      isAuthenticated,
      unverifiedEmailError,
      incompleteSetupError,
      account?.organizations,
      router.pathname,
    ]);

    useEffect(() => {
      if (!isAuthenticating) {
        if (!isAuthenticatedCustom) {
          router.replace({
            pathname: '/auth/login',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        } else if (isUnverified) {
          router.replace({
            pathname: '/verify-email',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        } else if (isUnsetup) {
          router.replace({
            pathname: '/account-setup',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        } else if (hasNoOrgs) {
          router.replace({
            pathname: '/org-setup',
            query: { returnTo: router.query.returnTo || router.asPath },
          });
        }
        // else if (isOnboardingIncomplete) {
        //   router.replace({
        //     pathname: '/onboarding',
        //     query: { returnTo: router.query.returnTo || router.asPath },
        //   });
        // }
      }
    }, [isAuthenticating, isAuthenticatedCustom, isUnverified, isUnsetup, hasNoOrgs, router]);

    useEffect(() => {
      if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog && account?.uid) {
        posthog.identify(account.uid, {
          email: account.email || 'unknown',
          name: `${account.first_name} ${account.last_name}` || 'unknown',
        });
      }

      if (account?.uid) {
        FB_PIXEL_INIT(account);
      }

      if (process.env.NEXT_PUBLIC_ENABLE_APPCUES === 'true' && account?.uid && window.Appcues) {
        window.Appcues.identify(account.uid),
          {
            accountId: account.uid,
            firstName: account.first_name,
            lastName: account.last_name,
            email: account.email,
          };
      }

      if (process.env.NEXT_PUBLIC_ENABLE_CHAMELEON === 'true' && account?.uid && window.chmln) {
        window.chmln?.identify(account.uid, {
          name: [account.first_name, account.last_name].join(' '),
          email: account.email,
        });
      }
    }, [account]);

    const showComponent =
      !isLoading && isAuthenticatedCustom && !isUnsetup && !isUnverified && !hasNoOrgs;

    const [loaderDelay, setLoaderDelay] = useState(false);

    useEffect(() => {
      const t = setTimeout(() => {
        setLoaderDelay(showComponent);
      }, 2000);
      return () => {
        clearTimeout(t);
        setLoaderDelay(showComponent);
      };
    }, [showComponent, setLoaderDelay]);

    // // if showComponent is true and showLoader is true
    // if (!showComponent) {
    //   return (
    //     <Box pos={'absolute'} left="0" top="0" width="100%" height="100%" backgroundColor="white">
    //       <Loader animate={false} />
    //     </Box>
    //   );
    // }

    // render the component but hide it until showLoader=false, to prevent flashes and give it a chance to load
    return (
      <Box height="100%" width="100%">
        {!loaderDelay && (
          <Box
            pos={'absolute'}
            left="0"
            top="0"
            width="100vw"
            height="100vh"
            backgroundColor="white"
            zIndex={10000}
          >
            <Loader />
          </Box>
        )}
        {showComponent && <Component {...props} />}
      </Box>
    );
  };
};

export default privateRoute;
