import React from 'react';
import Head from 'next/head';

import privatePage from 'hocs/privatePage';
import HomeView from 'views/Home/HomeView';

function Home() {
  return (
    <>
      <Head>
        <title>Patterns</title>
      </Head>
      <HomeView />
    </>
  );
}

export default privatePage(Home);
