import { ReactNode } from 'react';
import { Box, ChakraProps, Flex } from '@chakra-ui/react';

import Header from 'layouts/PageLayout/Header';

type EditorLayoutProps = ChakraProps & {
  showHeader?: boolean;
  children: ReactNode;
};

function PageLayout({ showHeader = true, children }: EditorLayoutProps) {
  return (
    <Flex direction="column" height="100%">
      {showHeader && <Header />}
      <Flex grow={1} height={'100%'} overflowY={'inherit'}>
        <Box flex="1" data-testid="content" minW="0px" minH="100%">
          {children}
        </Box>
      </Flex>
    </Flex>
  );
}

export default PageLayout;
