import { memo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Label } from 'components/Typography';
import config from 'config';
import { useAuth } from 'contexts/AuthContext';
import useOrganization from 'hooks/useOrganization';

import OrganizationIcon from '../../../../public/icons/organization.svg';
import Organizations from './Organizations';

const logoHeight = '28px';

function Header() {
  const { logout, account, isAuthenticated } = useAuth();

  const { organization } = useOrganization();

  const hoverStyle = { bg: 'gray.50' };

  const router = useRouter();

  return (
    <Flex
      bg={'white'}
      justifyContent="space-between"
      h="45px"
      minH="45px"
      align="center"
      px={2}
      data-testid="header"
      zIndex={1200}
    >
      <Flex align="center">
        <Link href={config.defaultPathname} passHref>
          <Box px={2} display={{ base: 'none', md: 'block' }}>
            <Image src={'/logos/patterns-full.svg'} alt="logo compact" height={logoHeight} />
          </Box>
        </Link>
        <Link href={config.defaultPathname} passHref>
          <Box px={2} display={{ base: 'block', md: 'none' }}>
            <Image src={'/logos/patterns-icon.svg'} alt="logo compact" height={logoHeight} />
          </Box>
        </Link>
      </Flex>

      <Flex alignItems={'center'}>
        {isAuthenticated && (
          <Flex gap={3} justify="center" align="flex-start">
            <Flex display={'block'}>
              <Menu autoSelect={false}>
                <MenuButton
                  data-testid={'menu-button'}
                  as={Button}
                  variant="ghost"
                  borderColor={'gray'}
                  colorScheme="gray"
                >
                  <Flex alignItems="center">
                    <Icon as={OrganizationIcon} />
                    <Label mx={1}>{organization?.slug}</Label>
                    <FiChevronDown />
                  </Flex>
                </MenuButton>
                <MenuList zIndex={12} fontSize="sm" color="text2">
                  <MenuGroup>
                    <Flex
                      fontSize="11px"
                      fontWeight={400}
                      mx={3}
                      my={2}
                      justifyContent={'space-between'}
                    >
                      <Text mr={1}>{account?.email}</Text>
                      <Text ml={1}>{account?.username}</Text>
                    </Flex>

                    <MenuDivider />
                    <Box maxH="200px" overflowY="auto" my="-0.5rem">
                      {account?.organizations && (
                        <Organizations organizations={Object.values(account?.organizations)} />
                      )}
                    </Box>

                    <MenuDivider />
                  </MenuGroup>
                  <MenuItem
                    textStyle="label"
                    onClick={() => router.push('org-setup')}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                    data-testid="create-org-button"
                  >
                    Create Organization
                  </MenuItem>
                  <MenuItem
                    textStyle="label"
                    onClick={() => logout()}
                    _hover={hoverStyle}
                    _focus={hoverStyle}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        )}
        {/*{!isAuthenticated && (*/}
        {/*  <Flex ml={'auto'}>*/}
        {/*    <Button*/}
        {/*      variant={'outline'}*/}
        {/*      borderRadius={'56px'}*/}
        {/*      size={'md'}*/}
        {/*      px={7}*/}
        {/*      color={'grey'}*/}
        {/*      borderColor={'border1'}*/}
        {/*      border={'2px solid'}*/}
        {/*      onClick={() => {*/}
        {/*        router.push({*/}
        {/*          pathname: '/auth/login',*/}
        {/*          query: { returnTo: router.query.returnTo || router.asPath },*/}
        {/*        });*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Sign up*/}
        {/*    </Button>*/}
        {/*  </Flex>*/}
        {/*)}*/}
      </Flex>
    </Flex>
  );
}

export default memo(Header);
