import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Graph } from 'types/api';
import { getCreateGraphsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

export type CreateGraphData = {
  title: string;
  storage_uid: string;
  organization_uid: string;
  tables?: string[];
};

const url = getCreateGraphsURL();

function useCreateGraph(
  options?: UseMutationOptions<Graph, AxiosError, CreateGraphData>,
): UseMutationResult<Graph, AxiosError, CreateGraphData> {
  return useMutation(async (data: CreateGraphData) => {
    const result = await Axios.post<Graph>(url, data);
    return result?.data;
  }, options);
}

export default useCreateGraph;
