import React, { useCallback, useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import ManageTables from 'modals/ManageTablesModal/ManageTables';
import { useRouter } from 'next/router';

import { H1 } from 'components/Typography';
import useManageTables from 'hooks/api/graphs/useManageTables';
import { useOrganizationUID } from 'hooks/useOrganization';
import { Graph } from 'types/api';
import { makeGraphURL } from 'utils';

import CreateGraphForm from './CreateGraphForm';

type CreateGraphModalProps = {
  onAddStorage: Function;
  storageUID?: string;
};

function CreateGraphFormWrapper({ onAddStorage, storageUID }: CreateGraphModalProps) {
  const [graph, setGraph] = useState<Graph | undefined>(undefined);
  const [addedTables, setAddedTables] = useState<string[]>([]);

  const router = useRouter();

  const { mutateAsync: addTables, isLoading: isAddingTables } = useManageTables();

  const queryClient = useQueryClient();

  const organizationUID = useOrganizationUID();

  const onAddTableSuccess = useCallback(async () => {
    if (!graph?.uid) return;
    try {
      await addTables({ graphUID: graph.uid, tables: addedTables });
    } catch (error) {
      console.warn(error);
    }
    await queryClient.invalidateQueries(['organizations', organizationUID, 'graphs']);
    await queryClient.invalidateQueries(['graph']);

    const graphUrl = makeGraphURL({ uid: graph?.uid! });
    await router.push({ pathname: graphUrl });
  }, [queryClient, graph?.uid, router, addTables, addedTables, organizationUID]);

  if (!graph) {
    return (
      <>
        <H1 textAlign={'center'} mb={5}>
          Name your agent
        </H1>
        <Text textAlign={'center'} fontSize="16px" color="text1" fontWeight={500} mb={5}>
          You can make many agents, but for now, let’s just get started with one.
        </Text>
        <CreateGraphForm
          onAddStorage={onAddStorage}
          storageUID={storageUID}
          onSuccess={(graph: Graph) => setGraph(graph)}
        />
      </>
    );
  } else {
    return (
      <>
        <H1 textAlign={'center'} mb={5} fontWeight={500}>
          Select tables to include
        </H1>
        <Text textAlign={'center'} fontSize="16px" color="text1" fontWeight={500} mb={1}>
          Choose which tables your agent is able to access and should focus on.
        </Text>
        <Text textAlign={'center'} fontSize="14px" color="text3" fontWeight={500} mb={5}>
          For best results, only select tables you want to reference.
        </Text>
        <Box
          mx={'auto'}
          width="100%"
          borderWidth="2px"
          borderRadius="lg"
          boxShadow="lg"
          p={5}
          maxWidth={'600px'}
        >
          <ManageTables
            Toggle={() => {}}
            graphUID={graph.uid}
            showFooter={false}
            onTableChanges={(newTables) => setAddedTables(newTables)}
          />
        </Box>
        <Button
          size="lg"
          my={6}
          color={'white'}
          bg={'#49526A'}
          _hover={{ opacity: 0.75 }}
          width={'100%'}
          maxWidth={'300px'}
          mx={'auto'}
          onClick={() => onAddTableSuccess()}
          isLoading={isAddingTables}
        >
          Continue
        </Button>
      </>
    );
  }
}

export default CreateGraphFormWrapper;
