export const QueryBaseQueryKey = (queryUID: string) => {
  return ['graph', 'query', queryUID];
};

export const QueryMetadataQueryKey = (queryUID: string) => {
  return [...QueryBaseQueryKey(queryUID), 'metadata'];
};

export const TableDataQueryKey = (queryUID: string) => {
  return [...QueryBaseQueryKey(queryUID), 'data'];
};

export const ChartDataQueryKey = (queryUID: string) => {
  return [...QueryBaseQueryKey(queryUID), 'chart-data'];
};

export const ChartBaseQueryKey = (chartUID: string) => {
  return ['graph', 'chart', chartUID];
};

export const ChartUrlQueryKey = (chartUID: string, updatedAt?: string) => {
  return [...ChartBaseQueryKey(chartUID), 'url', updatedAt];
};

export const ChartSpecQueryKey = (chartUID: string, updatedAt?: string) => {
  return [...ChartBaseQueryKey(chartUID), 'spec', updatedAt];
};

export const LatestExecutionsQueryKey = (graphUID: string) => {
  return ['graph', graphUID, 'latest_executions'];
};

export const QueryExecutionsQueryKey = (queryUID: string) => {
  return [...QueryBaseQueryKey(queryUID), 'executions'];
};

export const GraphStorageQueryKey = (graphUID: string) => {
  return ['graphs', graphUID, 'storage'];
};

export const StorageQueryKey = (storageUid: string) => {
  return ['storage', storageUid];
};

export const StoragesQueryKey = () => {
  return ['storages'];
};

export const GraphQueryKey = (graphUID: string) => {
  return ['graph', graphUID, 'metadata'];
};

export const GraphQueriesBaseQueryKey = (graphUID: string) => {
  return ['graph', graphUID, 'queries'];
};

export const GraphQueriesQueryKey = (
  graphUID: string,
  queriesToShow: string,
  pageSize: number,
  search?: string,
) => {
  return [...GraphQueriesBaseQueryKey(graphUID), queriesToShow, pageSize, search];
};

export const GraphTablesQueryKey = (graphUID: string, pageSize?: number) => {
  let key: any[] = ['graph', graphUID, 'tables'];
  if (pageSize) {
    key.push(pageSize);
  }
  return key;
};

export const GraphAvailableTablesQueryKey = (graphUID: string) => {
  return ['graph', graphUID, 'available_tables'];
};

export const TableMetadataQueryKey = (graphUID: string, tableUID?: string) => {
  return ['graph', graphUID, 'tables', tableUID, 'metadata'];
};

export const chatsKey = (graphUID: string) => {
  return ['graph', graphUID, 'chats'];
};

export const chatsKeyWithSearch = (graphUID: string, pageSize: number, search?: string) => {
  return [...chatsKey(graphUID), pageSize, search];
};

export const chatKey = (chatUID: string) => {
  return ['graph', 'chat', chatUID];
};

export const ChatQueriesQueryKey = (chatUID: string) => {
  return ['graph', 'chat', chatUID, 'queries'];
};

export const ChatAiCompletionsQueryKey = (chatUID: string) => {
  return ['graph', 'chat', chatUID, 'ai_completions'];
};

export const ChatAddMessageMutationKey = (chatUID: string) => {
  return ['graph', 'chat', chatUID, 'message'];
};

export const chatMessagesKeys = (chatUID: string, page: number) => {
  return ['graph', 'chats', chatUID, 'messages', page];
};

export const chatMessageKey = (chatMessageUID?: string) => {
  return ['graph', 'chat_message', chatMessageUID];
};

export const suggestedChatQuestionsKey = (graphUID: string) => {
  return ['graph', graphUID, 'chatQuestionSuggestions'];
};

export const organizationMembersKey = (organizationUID?: string) => {
  return ['organization', 'members', organizationUID];
};

export const organizationInvitesKey = (organizationUID?: string) => {
  return ['organization', 'invites', organizationUID];
};

export const userNotificationsKey = () => {
  return ['user', 'notifications'];
};

export const graphDocumentsKey = (graphUID: string) => {
  return ['graph', graphUID, 'documents'];
};

export const graphMetricsKey = (graphUID: string) => {
  return ['graph', graphUID, 'metrics'];
};
